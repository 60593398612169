@import '../../../styles/utilities/colors.scss';
.LoginPage{
    // background: url('../../../assets/img/auth/login-bg-new.png');
    // background: url('../../../assets/img/auth/login-bg-jpeg-1.jpeg');
    background: url('../../../assets/img/auth/login-bg-hand.png');
    // background: url('../../../assets/img/auth/login-bg-jpeg.jpeg');
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    // background: url('../../../assets/img/auth/login-bg.png');
    // background-size: contain;
    // background-repeat: no-repeat;
    // background-image: url('https://img.freepik.com/free-photo/painting-jungle-scene-with-green-plant-green-leafy-plant_1340-31601.jpg?w=1060&t=st=1684955858~exp=1684956458~hmac=9e14e562a49ff0fa48eb2cb51ad63a6a145414668664c0adbe4c44f9802ee0a9');
    code{
        color: $color-main;
    }
    .btnLogin{
        background-color: $color-main;
        color: $color-white;
        border-color: $color-main;
    }
    .vid-container{
        position:relative;
        height:100vh;
        overflow:hidden;
      }
      .bgvid{
        position:absolute;
        left:0;
        top:0;
        width:100vw;
      }
}